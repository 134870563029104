<template>
    <div class="page_content locus_content">
        <div class="locus_left">
            <Form :form_data="form_data" @handleSubmit="handleSubmit" @handleReset="handleReset"></Form>
            <a-table
                :showHeader="false"
                :columns="columns"
                :pagination.sync="pagination"
                :dataSource="list"
                @change="handleTableChange"
                :customRow="customRow"
                :loading="loading"
                style="table_list"
            >
                <div slot="name" slot-scope="text,data">{{data.username}}拜访了{{data.visit}}家客户</div>
            </a-table>
        </div>
        <div class="locus_right">
            <div class="locus_header">
                <div class="time">{{now_date}}</div>
                <div v-if="!info.user.username" class="day_content">
                    <div class="tit">今日概况</div>
                    <div class="dl">
                        <div class="dd">在线人数：{{default_info.online_num}}</div>
                        <div class="dd">拜访次数：{{default_info.visit_num}}</div>
                    </div>
                    <div class="dl">
                        <div class="dd">离线人数：{{default_info.not_online_num}}</div>
                        <div class="dd">订单金额：{{default_info.order_total}}元</div>
                    </div>
                </div>
                <div v-else class="user_content">
                    <div class="user">
                        <!-- :src="head_img" -->
                        <a-avatar :size="60" />
                        <div class="info">
                            <div class="name">{{info.user.username}}</div>
                            <div class="tip">岗位职务：{{info.user.role}}</div>
                            <div class="tip">部门：{{info.user.department}}</div>
                        </div>
                    </div>
                    <div class="dl">
                        <a href="javascript:;" @click="locus_date(1)" class="tit">今日轨迹</a>
                        <div class="dd">拜访客户数：{{info.day.customer}}家</div>
                        <div class="dd">拜访总次数：{{info.day.visit_num}}次</div>
                        <div class="dd">订单金额：{{info.day.order_price}}元</div>
                    </div>
                    <div class="dl">
                        <a href="javascript:;" @click="locus_date(7)" class="tit">近7天轨迹</a>
                        <div class="dd">拜访客户数：{{info.week.customer}}家</div>
                        <div class="dd">拜访总次数：{{info.week.visit_num}}次</div>
                        <div class="dd">订单金额：{{info.week.order_price}}元</div>
                    </div>
                    <div class="dl">
                        <a href="javascript:;" @click="locus_date(30)" class="tit">近30天轨迹</a>
                        <div class="dd">拜访客户数：{{info.month.customer}}家</div>
                        <div class="dd">拜访总次数：{{info.month.visit_num}}次</div>
                        <div class="dd">订单金额：{{info.month.order_price}}元</div>
                    </div>
                </div>
            </div>
			<VisitMap :points="points" :center="center"></VisitMap>
            <!-- <baidu-map
                class="baidu_map"
                :MapType="'BMAP_SATELLITE_MAP'"
                :points="points"
                :center="center"
                :zoom="zoom"
            >
                <bm-point-collection
                    :points="points"
                    shape="BMAP_POINT_SHAPE_CIRCLE"
                    color="red"
                    size="BMAP_POINT_SIZE_SMALL"
                    @click="handler_points_click"
                ></bm-point-collection>
            </baidu-map> -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import VisitMap from "@/components/VisitMap";
import { locusList, locusMapHistory, locusTopInfo } from "@/api/locus";

const columns = [
    {
        title: "ID",
        dataIndex: "id",
        scopedSlots: {
            customRender: "name"
        }
    },
];


export default {
	name: "Index",
	components: {
        EditPop,
		Form,
		VisitMap
    },
    data() {
        return {
            center: { lng: 115.86631, lat: 28.695285 },
            now_date: '',
            zoom: 13,
            keyword: '',
            location: '南昌',
            expand: false,
			page: 1,
			user:{},
			is_page:true,
			pagination: { ...this.$config.pagination ,
				simple:true,
			},
            loading: false,
            list: [],
            seo_data: {
                is_online: '',
                times: 30
            },
            columns,
            points: [],
            customRow: (record) => {
                return {
                    on: {
                        click: (event) => {
                            this.get_info(record)

                        }
                    }
                }
            },
            info: {
                user: {},
                day: {},
                week: {},
                month: {},
            },
            default_info: {

            },
            visible: false,
            form_data: {
                ...this.$config.form_data_seo,
                item_margin_bottom: '8px',
                layout: 'horizontal',
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "tree-select",
                        name: "role",
                        title: "岗位",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    // {
                    //     type: "select",
                    //     name: "role",
                    //     title: "岗位",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
					// },
					{
						type: "select",
						name: "user_status",
						title: "状态",
						mode: "default",
						options: {},
						list: this.$config.user_status_list
					},
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/手机号',
                        options: {

                        }
                    },
                    {
                        type: 'radio',
                        mode: 'button',
                        name: 'is_online',
                        title: '',
                        options: {
                            initialValue: ''
                        },
                        list: [
							{
                                key: '',
                                value: '全部用户'
                            },
                            {
                                key: 1,
                                value: '在线用户'
                            },
                            {
                                key: 0,
                                value: '离线用户'
                            },

                        ]
                    },
                    {
                        type: 'radio',
                        mode: 'button',
                        name: 'times',
                        title: '',
                        options: {
                            initialValue: 30
                        },
                        list: [
                            {
                                key: 1,
                                value: '今日拜访'
                            },
                            {
                                key: 7,
                                value: '7日拜访'
                            },
                            {
                                key: 30,
                                value: '30日拜访'
                            },

                        ]
                    },
					
                ]
            }
        };
    },

    
    computed: {

    },
    async created() {
        this.now_date = this.$method.get_date(this.$moment());
        this.get_list();
		this.get_info();

		Promise.all([this.$method.get_department(),this.$method.get_role_tree()]).then(res=>{
			this.form_data.list.forEach(item => {
                if (item.name == "department") {
                    item.treeData = res[0];
                }else if(item.name == "role"){
					// item.list = [...this.$config.status_all, ...res[1]];
                    item.treeData = res[1];
				}
            });
		});

    },
    methods: {
        handleTableChange(pagination) {
            this.page = pagination.current;
            this.get_list();
        },

        handleReset() {
            this.list = [];
            this.seo_data = {};
            this.get_list();
        },
        handleSubmit(e) {
			let data = e.values;
			if(Array.isArray(data.department)){
				data.department = data.department.join(',');
			}
            if(Array.isArray(data.role)){
				data.role = data.role.join(',');
			}
			
            this.seo_data = data;
            this.list = [];
            this.get_list();
        },
        get_markersset() {

		},
		locus_date(times){
			this.get_map(this.user,{
				times,
			})
		},
        get_map(record,data = {}) {
			this.user = record;
            locusMapHistory({
                data: {
                    ...this.seo_data,
					id: record.id,
					...data
                }
            }).then(res => {
                this.points = res.data.list.map((item, index) => {
                    if (index == 0) {
                        this.center = { lng: item.longitude, lat: item.latitude };
                    }
                    return {
                        lng: item.longitude,
                        lat: item.latitude,
                        ...item,
                    }
                })
            })
        },
        get_info(record = {}) {
            let data = { ...this.seo_data };
            if (record.id) {
                data.id = record.id;
                this.get_map(record)
            }
            locusTopInfo({
                data,
            }).then(res => {
                if (record.id) {
                    this.info = res.data
                } else {
                    this.default_info = res.data
                }

            })
        },
        get_list() {
            locusList({
                _this: this,
                data: {
                    ...this.seo_data,
                }
            }).then(res => {
            })
        },
        handler_points_click() {

        }


    }
};
</script>

<style lang="less"  scoped>
@import url("../../assets/less/app.less");

.locus_content {
    display: flex;
    height: calc(~"100% - 30px");
}
.locus_left {
	width: 300px;
	overflow-y:auto;
}
.locus_right {
    flex: 1;
    margin-left: 15px;
    height: 100%;
    position: relative;
    .user_content {
        display: flex;
        align-items: center;
        .user {
            display: flex;
            margin-right: 30px;
            .name {
                font-size: 18px;
            }
            .info {
                margin-left: 15px;
            }
        }
        .dl {
            margin-right: 30px;
            .tit {
                padding-bottom: 8px;
                font-size: 18px;
            }
            .dd {
                padding: 3px 0;
            }
        }
    }
    .locus_header {
        position: absolute;
        z-index: 1;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
        .day_content {
            display: flex;
            align-items: center;
            padding-top: 15px;
            .tit {
                padding-right: 15px;
                font-size: 24px;
                border-right: 1px solid #fff;
            }
            .dl {
                margin: 0 15px;

                .dd {
                    padding: 5px 0;
                    font-size: 18px;
                }
            }
        }
    }
}
.table_list {
    margin-top: 0;
}
.map {
    width: 100%;
    height: 100%;
}

.baidu_map {
    width: 100%;
    height: 100%;
}
</style>